.component-dock {
  position: fixed;
  bottom: 16px;
  width: 100vw;

  //   @include absoluteCenterX();

  .dock-item {
    cursor: pointer;
    width: 80%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .tooltip {
      display: none;
    }

    .active-dot {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      bottom: 2px;
      @include absoluteCenterX();
    }

    &:hover {
      .tooltip {
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        font-size: 12px;
        border-radius: 8px;
        padding: 2px 8px;
        display: block;
        position: absolute;
        bottom: 100%;
        white-space: nowrap;
        @include absoluteCenterX();
      }
    }
  }

  .dock-bg {
    background-color: #fff;
    opacity: 0.1;
    border-radius: 12px;
  }
}
