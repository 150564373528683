.component-listview {
  padding: 8px 12px;
  overflow-y: scroll;
  .component-folder-list {
    @extend .unselectable;
    height: 28px;
    padding: 4px 12px;
    border-radius: 4px;

    &.odd {
      background-color: #3b3036;
    }

    &.clicked {
      background-color: #1758d0;
    }

    img {
      height: 100%;
    }
  }
}
