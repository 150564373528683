.component-header {
  position: relative;
  z-index: $header-zindex;
  width: 100vw;
  height: 30px;
  padding: 4px 16px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
}
