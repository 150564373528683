.component-finder {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .finder-content {
    overflow-y: scroll;
    background-color: #31262c;
  }

  &.dark {
    background-color: #2a2630;
  }

  .view-types {
    cursor: auto;
    padding: 4px 8px;
    border-radius: 4px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    img {
      width: 40px;
      height: 26px;
      object-fit: contain;
      padding: 4px 12px;
      border-radius: 4px;
      &.active {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }
}
