*,
*::before,
*::after {
  box-sizing: border-box;
}

hr {
  width: 100%;
  margin: 1.875rem 0;
  border: solid 1px rgba(255, 255, 255, 0.1);
}
