html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 16px;
  width: 100%;
  height: 100%;
  background-color: #111229;
  position: fixed;
  overflow: hidden;
}

svg {
  shape-rendering: crispEdges;
}

#react-app {
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

* {
  flex-shrink: 0;
}

.link {
  cursor: pointer;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.underlined-link {
  text-decoration: underline;
  cursor: pointer;
}

@for $i from 0 through 60 {
  .top-#{$i * 5} {
    margin-top: #{$i * 0.3125}rem;
  }

  .right-#{$i * 5} {
    margin-right: #{$i * 0.3125}rem;
  }

  .bottom-#{$i * 5} {
    margin-bottom: #{$i * 0.3125}rem;
  }

  .left-#{$i * 5} {
    margin-left: #{$i * 0.3125}rem;
  }

  .padding-#{$i * 5} {
    padding: #{$i * 0.3125}rem;
  }

  .width-#{$i * 2} {
    width: #{$i * 0.125}rem;
  }

  .height-#{$i * 2} {
    height: #{$i * 0.125}rem;
  }

  .font-#{$i * 2} {
    font-size: #{$i * 0.125}rem;
  }
}

.font-11 {
  font-size: 0.6875rem;
}

.max-width {
  width: 100%;
}

.cap-max-width {
  max-width: 100%;
}

.max-height {
  height: 100%;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  left: 0;
  top: 0;
}

.padded-horizontal {
  padding-left: calc((100% - 1320px) / 2);
  padding-right: calc((100% - 1320px) / 2);

  @include responsive(1400px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  // @include responsive(mobile) {
  //   padding-left: 1.25rem;
  //   padding-right: 1.25rem;
  // }
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-visible {
  overflow: visible;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.bordered {
  border: solid 2px $primary;
}

.bordered-grey {
  padding: 10px 15px;
  border: solid 1px #4a4a4a;
}

.border-50 {
  border-radius: 50%;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-x-180 {
  transform: rotateX(180deg);
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.test {
  background-color: blue;
}

.line-height-0 {
  line-height: 0;
}
