.component-window {
  border-radius: 12px;
  overflow: hidden;
  .dark-overlay {
    @extend .absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
  }

  .window-header {
    background-color: #3f393f;
    width: 100%;
    padding: 0 24px;
    height: 64px;
    position: fixed;
    top: 0;
    left: 0;
    cursor: move;
  }

  .window-content {
    background-color: #31292f;
    overflow-y: scroll;
  }

  .close-buttons {
    cursor: auto;
    .round-button {
      width: 16px;
      height: 16px;
      background: #525251;
      border-radius: 50%;
      //     #f95f57
    }

    .round-button + .round-button {
      margin-left: 12px;
    }

    &:hover {
      .close {
        background-color: #f95f57;
      }

      .minimize {
        background-color: #fbbb30;
      }

      .expand {
        background-color: #31c841;
      }
    }
  }
}
