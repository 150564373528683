$primary: #c6f120;
$secondary: #ffffff;
$text-grey: #777174;
$bsc: #f0b90b;
$twitter: #54a9eb;
$hunt: #fc6f6f;
$pancake: #d1884f;
$onesmarket: #ff4142;
$eth: #627eea;
$discord: #5865f2;
$mac-blue: #3f96f6;
$link: #3f96f6;
$text-light-grey: #828589;
$bg: #141415;
$warning: #e02020;
$discord-zindex: 8;
$component-zindex: 10;
$modal-zindex: 10;

$header-zindex: 99;
