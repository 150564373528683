.component-finder-header {
  padding: 24px;
  background-color: #39343d;
  color: white;
  border-bottom: 1px solid black;
  cursor: move;

  svg {
    cursor: auto;
    fill: #5b5860;
    &.active {
      fill: #b7b4ba;
    }
  }
}
