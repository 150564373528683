.page-mac {
  width: 100%;
  height: 100%;

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      object-fit: cover;
    }
  }
}
