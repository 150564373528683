.component-sidebar {
  width: 200px;
  height: 100%;
  border-right: solid 1px black;
  padding: 24px 0;

  .left-24 {
    padding-left: 24px;
  }

  .sidebar-items {
    padding: 0 16px;
  }
}
