.component-folder {
  width: 100px;
  margin-bottom: 20px;
  img {
    width: auto;
    height: 70px;
    object-fit: contain;
    padding: 4px 8px;
    border-radius: 4px;
  }

  .title {
    max-width: 90px;
    text-align: center;
    margin-top: 4px;
    white-space: normal;

    @include ellipsis(90px);
  }

  &.clicked {
    img {
      background-color: #443e43;
    }
    .title {
      background-color: #0058d0;
      border-radius: 4px;
      padding: 0 4px;
    }
  }
}
