html {
  // font-family: "Space Mono", sans-serif;
  font-weight: 400;
  line-height: 1.34;
  font-size: 16px;
  word-break: keep-all;

  @include responsive(tablet) {
    font-size: 14px;
  }

  @include responsive(mobile) {
    font-size: 12px;
  }
}

@include responsive(tablet) {
  br {
    content: "";
    &::after {
      content: " ";
    }
  }
}

.maintain-br {
  br {
    all: unset !important;
  }
}

.raleway {
  font-family: "Raleway", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
b,
strong {
  font-weight: 400;
}

b {
  font-weight: 500;
}

a {
  color: $link;
  text-decoration: none;
}

small {
  font-size: 0.75rem;
}

.strikethrough {
  text-decoration: line-through;
  text-decoration-color: $warning;
}

.bold,
.bold * {
  font-weight: 600 !important;
}

.weight-900 {
  font-weight: 900;
}

.primary {
  color: $primary;
}

.primary-hover {
  transition: color 150ms ease-in-out;
  &:hover {
    color: $primary;
  }
}

.pointer {
  cursor: pointer;
}

.secondary {
  color: $secondary;
}

.black {
  color: black;
}

.mac-blue {
  color: $mac-blue;
}

.grey {
  color: $text-grey;
}

.light-grey {
  color: $text-light-grey;
}

.lighter-grey {
  color: #a8acb0;
}

.metamask {
  color: #df791c;
}

.mint {
  color: #64ffa1;
}

.twitter {
  color: $twitter;
}

.discord {
  color: $discord;
}

.green {
  color: #05b169;
}

.red {
  color: #e02020;
}

.white {
  color: #fff;
}

.bsc {
  color: $bsc !important;
}

.hunt {
  color: $hunt;
}

.eth {
  color: $eth;
}

.pancake {
  color: $pancake;
}

.uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.italic {
  font-style: italic;
}

.break-word {
  word-break: break-word;
}

.line-height-1-5 {
  line-height: 1.5;
}
